import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as ProfileDucks from 'ducks/accounts/profile';

import Singleton from 'lib/singleton';
import LoginFormSection from 'sections/accounts/login/Form';
import styles from './styles.module.scss';

/**
 * LoginPage
 *
 * Layout:
 *    - {@link ContentLayout}
 *
 * Sections:
 *    - {@link LoginFormSection}
 *
 * Fetch Needs:
 *    None
 */

class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
  }
  
  componentDidMount() {
    if (!this.singleton.authorization) {
      this.props.ProfileDucks.getProfile().catch(e=> '');
      this.props.ProfileDucks.getFaceDetails().catch(e=> '')
    }
  }

  render() {
    if (this.singleton.authorization) {
      return <Navigate to="/dashboard" />;
    }
    return (
      <div className="w-100">
        <div className="min-vh-100 items-center ">
          <div className={`w-100-l min-vh-100 flex items-center justify-center ${styles['bg']}`}>
            <LoginFormSection />
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.fetchData = ({ store }) => {
  return store.dispatch(ProfileDucks.getProfile());
};

const mapStateToProps = state => ({
  profile: ProfileDucks.profile(state),
});

const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
});

LoginPage.propTypes = {
  ProfileDucks: PropTypes.object.isRequired,
  profile: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(LoginPage);
