import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import routes from "../routes";
import configureStore from "../store";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import SectionLoaderReloadBtnAtom from "atoms/SectionLoaderReloadButton";

const history = createBrowserHistory();
/* Images
 * This space is reserved for images that are required by server rendering,
 * e.g. the favicon and any other images that need to be in the base HTML file.
 */

// Creates the Redux store based on the initial state passed down by the server
// rendering.
const initialState = window.__INITIAL_STATE__;
const store = configureStore(initialState, history);


const App = () => (
  <section>
    <Provider store={store}>
      <ToastContainer hideProgressBar />
      <BrowserRouter history={history}>
        <Suspense fallback={<SectionLoaderReloadBtnAtom active showReloadButton={false}/>}>
          <Routes>
            {routes.map((route) => (
              <Route path={route.path} Component={route.component} />
            ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </section>
);

export default App;
