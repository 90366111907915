import { createSelector } from 'reselect';

import Cookies from 'universal-cookie';
import { mergeDeepLeft } from "ramda";
import { fetch, post, put } from 'lib/api';
import Singleton from 'lib/singleton';
import createReducer from 'lib/createReducer';
import constants from './constants';

const cookies = new Cookies();

const GET_EMPLOYEES = 'employees/employee/GET_EMPLOYEES';
const GET_EMPLOYEES_FULFILLED = 'employees/employee/GET_EMPLOYEES_FULFILLED';
const GET_EMPLOYEE_COMPANY_SETTING = 'employees/employee/GET_EMPLOYEE_COMPANY_SETTING';
const GET_EMPLOYEE_COMPANY_SETTING_FULFILLED = 'employees/employee/GET_EMPLOYEE_COMPANY_SETTING_FULFILLED';
const GET_EMPLOYEE = 'employees/employee/GET_EMPLOYEE';
const GET_EMPLOYEE_FULFILLED = 'employees/employee/GET_EMPLOYEE_FULFILLED';
const POST_EMPLOYEE_ACTIVATE = 'employees/employee/POST_EMPLOYEE_ACTIVATE';
const POST_EMPLOYEE_ACTIVATE_FULFILLED = 'employees/employee/POST_EMPLOYEE_ACTIVATE_FULFILLED';

export function getEmployees(params) {
  return {
    type: GET_EMPLOYEES,
    payload: fetch(constants.URLS.EMPLOYEE, { params }),
  };
}

export function getEmployee(params) {
  return {
    type: GET_EMPLOYEE,
    payload: fetch(`${constants.URLS.EMPLOYEE}${params.id}/`),
  };
}

export function getEmployeeCompanySetting(params) {
  return {
    type: GET_EMPLOYEE_COMPANY_SETTING,
    payload: fetch(constants.URLS.COMPANY, { params }),
  };
}

export function postActivate(data) {
  return {
    type: POST_EMPLOYEE_ACTIVATE,
    payload: post(constants.URLS.ACTIVATE, data),
  };
}


const defaultState = {
  employeeId : null,
  list: { },
  detail: {},
  companyDetail: {},
};

function GET_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const appendArray = [...action.payload.results];
  return Object.assign({}, state, {
    list: appendArray,
  });
}

function GET_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}


function GET_EMPLOYEE_COMPANY_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    companyDetail: action.payload,
  });
}
function POST_EMPLOYEE_ACTIVATE_FULFILLED_REDUCER(state, action) {
  const singleton = new Singleton();
  singleton.employeeAuth = action.payload.auth_token;
  cookies.set('employeeToken', action.payload.auth_token, { path: '/' });
  return mergeDeepLeft(state, {
    employeeToken: action.payload.auth_token,
  });
}


const handlers = {
  [GET_EMPLOYEES_FULFILLED]: GET_EMPLOYEES_FULFILLED_REDUCER,
  [GET_EMPLOYEE_FULFILLED]: GET_EMPLOYEE_FULFILLED_REDUCER,
  [GET_EMPLOYEE_COMPANY_SETTING_FULFILLED]: GET_EMPLOYEE_COMPANY_SETTING_FULFILLED_REDUCER,
  [POST_EMPLOYEE_ACTIVATE_FULFILLED]: POST_EMPLOYEE_ACTIVATE_FULFILLED_REDUCER,
};


const employeeSelector = state => state.employees.employee;

export const employeeList = createSelector(
  employeeSelector,
  instance => instance.list,
);

export const employeeDetail = createSelector(
  employeeSelector,
  instance => instance.detail,
);

export const companyDetail = createSelector(
  employeeSelector,
  instance => instance.companyDetail,
);

export default createReducer(defaultState, handlers);
