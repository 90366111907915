import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as ProfileDucks from 'ducks/accounts/profile';

import Singleton from 'lib/singleton';
import RegisterFormSection from 'sections/accounts/register/Form';

/**
 * RegisterPage
 *
 * Layout:
 *    - {@link ContentLayout}
 *
 * Sections:
 *    - {@link RegisterFormSection}
 *
 * Fetch Needs:
 *    None
 */
class RegisterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.singleton = new Singleton();
  }

  componentDidMount() {
    if (!this.singleton.authorization) {
      this.props.ProfileDucks.getProfile();
    }
  }

  render() {
    if (this.singleton.authorization) {
      return <Navigate to="/dashboard" />;
    }
    return <RegisterFormSection />;
  }
}


const mapActionsToProps = dispatch => ({
  ProfileDucks: bindActionCreators(ProfileDucks, dispatch),
});

RegisterPage.propTypes = {
  ProfileDucks: PropTypes.object.isRequired,
};

export default connect(
  mapActionsToProps,
)(RegisterPage);
