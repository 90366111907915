import React from "react";
import * as serviceWorker from "./serviceWorker";
import ReactDOM from "react-dom/client";
import Cookies from "universal-cookie";

import Singleton from "lib/singleton";
import App from "pages/App";
import "images/favicon.png";

import "css/index.css";

const cookies = new Cookies();

const singleton = new Singleton();
singleton.authorization = cookies.get("authToken", { path: "/" });
singleton.employeeAuth = cookies.get("employeeToken", { path: "/" });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
