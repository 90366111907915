export const SP_TRANS = {
  useCamera: "Usar Cámara",
  search: "Buscar",
  login: "Iniciar sesión",
  reset: "Reiniciar",
  enterEmployeeName: "Ingrese el nombre del empleado",
  setupBiometricLogin: "Configurar inicio de sesión biométrico",
  setupPincodeLogin: "Configurar inicio de sesión con código PIN",
  retryPincodeLogin: "Reintentar iniciar sesión con código PIN",
  enterPincode: "Ingrese el código PIN",
  pincode: "Código PIN",
  resetPincode: "Restablecer código PIN",
  confirmPincode: "Confirmar código PIN",
  confirm: "Confirmar",
  enterPincodeToReset: "Ingrese el código PIN para restablecer",
  pincodeSetup: "Configuración del código PIN",
  enterPincodeToSetupPincodeRecognition: "Ingrese el código PIN para configurar su reconocimiento de código PIN",
  searchName: "Nombre de búsqueda",
  unableToLoginUsingPincode : "¿Si no puedes iniciar sesión usando el código PIN? Puedes usar tu contraseña",
  enterPincodeToLogin: "Ingrese el código PIN para iniciar sesión",
  alignYourFacewithCameraToLogin: "Alinea tu cara con la cámara para iniciar sesión",
  enterPasswordToLogin: "Ingrese la contraseña para iniciar sesión",
  loginFailed: "error de inicio de sesion",
  unableToLoginUsingFacialRecognition: "Si no puede iniciar sesión mediante el reconocimiento facial? Puedes usar tu contraseña.",
  retryFacialLogin: "Reintentar inicio de sesión facial",
  enterPassword: "Usar su contraseña",
  password: "Contraseña",
  accountIsInactiveOrIncorrectPassword: "Su cuenta está inactiva o tal vez la contraseña  es incorrecta",
  resetFacialRecognition: "Restablecer el reconocimiento facial",
  trackYourDay: "Rastrear tu dia",
  logout: "Cerrar sesión",
  submit: "Enviar",
  enterPasswordToConfirm: "Ingrese contraseña para confirmar",
  feedbackResponse: "Respuesta de retroalimentación",
  cannotPunchInAndContactYourSupervisor: "No puede marcar. Por favor contacte a su supervisor",
  thanksForYourFeedback: "Gracias por sus comentarios",
  welcome: "Bienvenido",
  readyToStartYourDay: "Listo para comenzar tu dia?",
  selectJob: "Seleccionar trabajo",
  jobs: "Trabajos",
  enterJobName: "Ingrese el nombre del trabajo",
  totalRecords: "Registros Totales",
  tasks: "Tareas",
  enterTaskName: "Ingrese el nombre de la tarea",
  workShift: "Turno de trabajo",
  workShiftTime: "Horario de trabajo",
  jobNotes: "Notas de trabajo",
  switch: "Cambiar",
  mealBreak: "Decanso de comida",
  punchout: "Ponchar salida",
  addNameToYourShift: "Agregue un nombre a su turno: Opcional (100 palabras)",
  save: "Guardar",
  cancel: "Cancelar",
  enterShiftLabel: "Ingrese una etiqueta de turno",
  shiftLabelCondition: "La etiqueta de desplazamiento no debe tener más de 100 caracteres",
  addComment: "Añadir un comentario",
  enterComment: "Por favor ingrese un comentario",
  toalMealBreakTime: "Tiempo total de descanso para comer",
  endMealBreak: "Termina tiempo de comida",
  endYourMealBreak: "Puedes terminar tu descanso para comer en",
  nowEndYourMealBreak: "Ahora puedes finalizar su tiempo de comida",
  mealEndedAutomatically: "Su comida ha finalizado automáticamemte",
  shiftOverView: "Resumen de turno",
  edit: "Editar",
  approve: "Aprobar",
  submitCorrection: "Enviar Corrección",
  assign: "Asignar",
  select: "Seleccione",
  assignJobTasks: "Asignar trabajos/ tareas",
  editJobTaskTime: "Editar trabajo y tiempo de tarea",
  editingTimeAdjustJobTaskTime: "Cuando corriges tu tiempo automaticámente se ajustan los tiempos de trabajo y tareas.",
  enterValidTime: "Ingrese una hora válida",
  cannotOverlapNextJob: "No se puede superponer completamente el siguiente trabajo",
  cannotOverlapPreviousJob: "No se puede superponer completamente el trabajo anterior",
  selectValidPunchoutTime: "Seleccione una hora de salida válida",
  punchinLessThanPunchout: "El tiempo de entraeda no puede ser más que el tiempo de salida",
  selectJobAndTask: "Por favor seleccione trabajo y tarea",
  comment: "Comentario",
  changesToSchedule: "Parece que hiciste cambios en tu horario.", 
  leaveComment: " Por favor deja un cometnario.*",
  enterCommentToContinue: "Por favor ingrese un comentario para continuar",
  sessionMessageLength: "El mensaje de sesión debe tener menos de 500 caracteres",
  sessionAlreadyEnded: "La sesión ya terminó",
  resumeLastJobOrSwitchToNewOne: "Puede reanudar su último trabajo o cambiar a uno nuevo",
  resume: "Resumir",
  viewAllYourTimecardsHere: "¡Vea todas sus tarjetas de tiempo aquí!",
  timecardDetails: "Detalles de las tarjeta de tiempo",
  payrollName: "Nombre de nómina",
  payrollCycle: "Ciclo de nómina",
  payroll: "Nomina de sueldos",
  startDate: "Fecha de inicio", 
  endDate: "Fecha Final",
  apply: "Aplicar",
  total: "Total",
  regularTime: "Tiempo regular",
  noRecordsFound: "No se encontraron registros",
  customDateRange: "Rango de fechas personalizado",
  overtime: "Tiempo extra",
  doubleOvertime: "Doble tiempo extra",
  selectStartDate: "Seleccionar fecha de inicio",
  selectEndDate: "Seleccionar fecha de finalización",
  startDateEndDateComparison: "La fecha de inicio debe ser menor o igual que la fecha de finalización",
  date: "Fecha",
  workDayTotal: "Jornada laboral total",
  shift: "Turno",
  mealPeriod: "Período de comida",
  moreInfo: "Más información",
  adjustmentActivity: "Actividad de ajuste",
  totalWeeklyHours: "Total de horas semanales",
  employeeActivatedSucessfully: "Empleado activado con éxito",
  signIn: "Registrarse",
  username: "Nombre de usuario",
  quickMode: "Modo rápido",
  standardMode: "Modo estandar",
  enterUsername: "Iingresa nombre de usuario",
  enterCompanyName: "Incgresa la compania",
  welcomeToWorksanaKiosk: "Bienvenido a Worksana Kiosk",
  viewYouremployeeDailyTimecards: "¡Ve a ver las tarjetas de tiempo diarias de tus empleados!",
  goToLogin: "Ir a iniciar sesión",
  approveMyShift: "Yo apruebo mi turno",
  goBack: "Regresar",
  submitYourCommentForCorrectionRequest: "Envíe su comentario para solicitar una corrección",
  youHaveBeenPunchedOut: "Te han sacado afuera",
  ok: "Okay",
  wantToEndMeal: "¿Estás seguro de que quieres terminar la comida?",
  no: "No",
  yes: "Si",
  wantToStartMeal: "¿Estás seguro de que quieres comenzar tu comida?",
  wantToPunchIn: "¿Estás seguro de que quieres ponchar entrada?",
  wantToPunchOut: "¿Estás seguro de que quieres ponchar salida?",  
  approveYourShift: "Apruebe su turno",
  correction: "Corrección",
  activateYourAccount: "Activa tu cuenta",
  activationCode: "Código de activación",
  confirmPassword: "Confirmar contraseña",
  enterActivationCode: "Ingrese código de activación",
  passwordLength: "La contraseña debe contener al menos 6 caracteres",
  passwordLengthValidation: "La contraseña debe contener al menos 6 caracteres, 1 alfabeto en mayúscula y i carácter numérico",
  advanceBrowserCheck: "Comprobación avanzada del navegador",
  cameraPermission: "Permiso de la cámara",
  browseNotSupported: "Navegador no compatible!",
  upgradeWebBrowser: "Está utilizando una versión anterior del navegador web, actualícela a la última versión",
  allowCameraAccess: "Por favor permita el acceso de la cámara",
  browserNotCompatibleWithWebcamAnduseSafari: "El navegador no es compatible con la cámara web. Le recomendamos que utilice safari o un navegador web diferente.",
  initiateLoginAttempt: "Iniciar el intento de inicio de sesión",
  alignFaceToSetupFacialRecognition: "Alinee su cara con la cámara y mire directamente para configurar su reconocimiento facial",
  email: "Correo electrónico",
  register: "Registrarse",
  enterEmail: "Ingrese correo electrónico",
  enterConfirmPassword: "Ingresar confirmar contraseña",
  passwordMismatch: "contraseña no coincide",
  timecards: "Tarjetas de tiempo",
  dailyTimecard: "Tarjeta de tiempo diaria",
  timecardApproval: "Esta tarjeta de tiempo diaria requiere aprobación o corrección del administrador.",
  timecardApproved: "Esta tarjeta de tiempo diaria esta aprobada por el administrador.",
  department: "Departamento",
  team: "Equipo",
  hours: "HORAS",
  minutes: "MINUTOS",
  small_hours: "Horas",
  small_minutes: "Minutos",
  searchAndLogin: "Por favor Seleccione o busque su nombre e inicie sesión para registrar su tiempo.",
  hour: "Houra",
  minute: "Minuto",
  enterComments: "Ingrese un comentario",
  companyName:"Compania nombre",
  supervisorPasswordToConfirm: "Ingrese la contraseña de supervisor para confirmar",
  punchin: "Ponchar entrada",
  selectTask: "Seleccionar tarea",
  resetBiometricPassword: "establecer la contraseña biométrica",
  snap: "Chasquido",
  alignFaceWithCamTo: "Alinea tu cara con la cámara para",
  mealStart: "comienzo de la comida",
  enableCameraPermission: "Por favor Habilite el permiso de su cámara",
  English: "Inglés",
  Spanish: "Español",
  Bilingual: "Bilingüe",
  chooseLanguage: "Elegir el idioma",
  language: "Idioma",
  languageSettings: "ajustes de idioma",
  alignFaceWithCamToResetBiometricPassword: "Alinea tu cara con la cámara para establecer la contraseña biométrica",
  biometricSetup: "Configuración biométrica",
  maxCharacters: "Máx 500 caracteres",
  unallocatedTask: "Tarea no asignada",
  thanks: "Gracias",
  clockedIn: "Estas ponchado adrento",
  onBreak: "Estas en descanso",
  clockedOut: "Estas ponchado afuera",
  switchJobs: "Has cambiado de trabajo",
  allDepartments: "Todos los departamentos",
  allTeams: "Todos los equipos",
  correctionRequest: "Corrección solicitar",

  hintCenterFaceText:'Centra tu cara'
};

export default SP_TRANS;