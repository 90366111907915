import { lazy } from "react";
import LoginPage from "pages/accounts/Login";
import RegisterPage from "pages/accounts/Register";
import HomePage from "pages/generics/Home";

const DashboardPage = lazy(() => import("pages/generics/Dashboard"));
const JobShiftOverViewPage = lazy(() => import("pages/jobs/OverView"));
const TimecardDetailPage = lazy(() => import("pages/timecards/Detail"));
const JobCommentsPage = lazy(() => import("pages/jobs/Comment"));
const ActivityListPage = lazy(() => import("pages/activities/List"));
const JobShiftPage = lazy(() => import("pages/jobs/Shift"));
const MealBreakPage = lazy(() => import("pages/meals/MealBreak"));
const ActivityBiometricPage = lazy(() => import("pages/activities/Biometric"));
const ActivityPincodePage = lazy(() => import("pages/activities/Pincode"));
const JobListPage = lazy(() => import("pages/jobs/List"));
const QuickModePage = lazy(() => import("pages/generics/QuickModeSetup"));
const TaskListPage = lazy(() => import("pages/tasks/List"));
const JobNotesPage = lazy(() => import("pages/jobs/Notes"));
const SurveyFeedback = lazy(() => import("pages/jobs/SurveyFeedback"));
const ErrorPage = lazy(() => import("pages/generics/ErrorPage"));

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
  {
    component: LoginPage,
    exact: true,
    name: "accounts.login",
    path: "/accounts/login",
  },
  {
    component: RegisterPage,
    exact: true,
    name: "accounts.register",
    path: "/accounts/register",
  },
  {
    component: ActivityListPage,
    exact: true,
    name: "activity.list",
    path: "/activity/list",
  },
  {
    component: ActivityBiometricPage,
    exact: true,
    name: "activity.biometric",
    path: "/activity/biometric",
  },
  {
    component: ActivityPincodePage,
    exact: true,
    name: "activity.pincode",
    path: "/activity/pincode",
  },
  {
    component: TimecardDetailPage,
    exact: true,
    name: "timecard.daily",
    path: "/timecard",
  },
  {
    component: JobListPage,
    exact: true,
    name: "job.list",
    path: "/jobs/list",
  },
  {
    component: JobNotesPage,
    exact: true,
    name: "job.notes",
    path: "/jobs/notes",
  },
  {
    component: JobShiftPage,
    exact: true,
    name: "job.shift",
    path: "/jobs/shift",
  },
  {
    component: JobCommentsPage,
    exact: true,
    name: "job.comment",
    path: "/jobs/comment",
  },
  {
    component: JobShiftOverViewPage,
    exact: true,
    name: "job.overview",
    path: "/jobs/overview",
  },
  {
    component: SurveyFeedback,
    exact: true,
    name: "job.fedback",
    path: "/jobs/feedback",
  },
  {
    component: TaskListPage,
    exact: true,
    name: "task.list",
    path: "/tasks/list",
  },
  {
    component: MealBreakPage,
    exact: true,
    name: "meal.break",
    path: "/meals/break",
  },
  {
    component: DashboardPage,
    exact: true,
    name: "dashboard",
    path: "/dashboard",
  },
  {
    component: HomePage,
    exact: true,
    name: "home",
    path: "/",
  },
  {
    component: QuickModePage,
    exact: true,
    name: "quickMode",
    path: "/success",
  },
  {
    name: "404",
    component: ErrorPage,
    path: "*",
  },
];
