import { useNavigate, useLocation, useParams } from 'react-router-dom';

function withRouter(Component) {
   function ComponentWithRouterProp(props) {
      const location = useLocation();
      const navigate = useNavigate();
      const params = useParams();
      const queryParams = Object.fromEntries(new URLSearchParams(location?.search));
      return <Component {...props} location={location} navigate={navigate} params={params} query={queryParams}/>;
   }

   return ComponentWithRouterProp;
}

export default withRouter;