import { createSelector } from "reselect";
import { mergeDeepLeft } from "ramda";
import { fetch } from "lib/api";
import createReducer from "lib/createReducer";
import constants from "./constants";

const GET_PERMISSIONS = "access/permission/GET_PERMISSIONS";
const GET_PERMISSIONS_FULFILLED = "access/permission/GET_PERMISSIONS_FULFILLED";

export function getPermissions() {
  return {
    type: GET_PERMISSIONS,
    payload: fetch(constants.URLS.PERMISSION),
  };
}

const defaultState = {
  list: [],
};

function GET_PERMISSIONS_FULFILLED_REDUCER(state, action) {
  return mergeDeepLeft(state, {
    list: action.payload,
  });
}

const handlers = {
  [GET_PERMISSIONS_FULFILLED]: GET_PERMISSIONS_FULFILLED_REDUCER,
};

const permissionSelector = (state) => state.access.permission;

export const permissionList = createSelector(
  permissionSelector,
  (instance) => instance.list
);

export default createReducer(defaultState, handlers);
